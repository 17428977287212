.button {
    // Sizes
    width: 64px;
    height: 64px;
    margin: 5px;
    // Colors
    background-color: transparent;
    color: #272727;
    // Texts
    font-size: 16px;
    text-align: center;
    // line-height: 64px;
    // Borders
    border-radius: 12px;
    // Comportmements
    cursor: pointer;
    transition-duration: 0.15s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "BarlowCondensed-Regular";


    svg {
        width: 30px;
    }

    &:hover,
    &.activ {
        transition-duration: 0.15s;

        svg {
            path {
                fill: white !important;
            }
        }

    }
}

@media (min-width: 0) and (max-width: 915px) {
    .button {
        font-size: 15px;
    }
}