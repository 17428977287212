#carte {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 100px;
    right: 0px;

    .mapboxgl-ctrl-attrib-inner,
    .mapboxgl-ctrl-logo {
        display: none;
    }

    #carteLegend {
        position: absolute;
        bottom: 36px;
        right: 22%;
        transform: translateX(-50%);

        #playerLegend {
            width: fit-content;
            transform: translateX(-78%);
        }

        .captionContent {
                width: 180px;
        }

    }

    @media (min-width: 0) and (max-width: 992px) {

        #carteLegend {
            right: 3vh;
            bottom: 3vh;
            transform: translateX(0);

            .captionContent {
                padding: 3%;

                p {
                    font-size: 15px;
                    text-align: left;
                    width: calc(100% - 10px);
                }

                h3 {
                    font-size: 17px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}