#ficheProjet {
    // Position
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0px;
    top: 0px;
    // Sizes
    width: 45%;
    height: 100%;
    // Color
    background-color: white;
    // Paddings
    padding: 2.5% 2% 1% 2%;
    z-index: 10;
    animation: openFp 0.25s ease-in-out 1;
    justify-content: space-between;

    svg {
        path {
            fill: black;
        }
    }

    &::after {
        content: "";
        text-align: center;
        color: white;
        line-height: 60px;
        position: absolute;
        width: 0px;
        height: 60px;
        left: -30px;
        border-radius: 40px 0px 0px 40px;
        top: calc(50% - 40px);
        cursor: pointer;
    }


    &.closed {
        transform: translateX(45vw);
        transition-duration: 0.25s;
        transition-timing-function: ease-in-out;

        &::after {
            content: "◄";
            width: 40px;
            transition-delay: 0.3s;
            transition-duration: 0.25s;
        }

        &:hover {
            &::after {
                transition-duration: 0.15s;
                left: -35px;
            }
        }
    }



    #ficheProjetMedias {
        // min-height: 45vh;
        border-radius: 16px;
        overflow: hidden;

        #closeBtn {
            position: absolute;
            top: 1vh;
            right: 2vw;
            display: flex;
            cursor: pointer;
        }

        #player {
            position: relative;
            z-index: 10;
            width: 100%;
        }

        #miniPlayerLoading {
            height: 414px;
            text-align: center;
            line-height: 414px;
            color: white;
            animation: loading 10s;
        }

        #galerie {
            width: 100%;
            height: 414px;
            overflow: hidden;
            position: relative;

            #galerieCarrousel {

                display: flex;
                height: 100%;
                width: fit-content;
                overflow: visible;

                .galerieMedia {
                    display: flex;
                    justify-content: center;
                    // width: 736px;
                    height: 100%;
                    overflow: hidden;

                    img {
                        height: 100%;
                        user-select: none;
                        pointer-events: none;
                        border-radius: 12px;
                    }
                }
            }

            #galerieSelector {
                position: absolute;
                display: flex;
                width: 100%;
                justify-content: center;
                transform: translateY(-35px);
                gap: 4px;

                .selector {
                    margin: 4px;
                    height: 22px;
                    width: 22px;
                    border-radius: 50%;
                    background-color: #2b2b2b;
                    transition-duration: 0.25s;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .selectorCurrent {
                    background-color: #fff;
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                }
            }
        }
    }

    #ficheProjetContainerDesc {
        display: flex;
        height: 45vh;
        width: 100%;
        flex-direction: column;

        #ficheProjetTopDiv {
            width: 100%;
            display: flex;
            padding: 1% 0 1% 0;
            align-items: center;
            justify-content: space-between;

            #ficheProjetTitle {
                display: flex;
                width: 50%;

                #nameToUse {
                    display: flex;
                    font-family: "BarlowCondensed-Regular";
                    font-weight: bold;
                    font-size: 25px;
                }
            }

            #ficheProjetPhasage {
                display: flex;
                height: 35px;
                align-items: center;

                #fichePhasage {
                    display: flex;
                    font-size: 16px;
                    font-family: "BarlowCondensed-Regular";
                    border-radius: 12px;
                    color: white;
                    padding: 6px;
                    width: fit-content;

                    &.done {
                        background-color: rgb(57, 172, 155);
                    }

                    &.inProgress {
                        background-color: rgb(245, 61, 61);
                    }

                    &.comingSoon {
                        background-color: rgb(89, 145, 192);
                    }
                }
            }

            #ficheProjetContainerToggle {
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: flex-end;
                height: 40px;

                #ficheProjetToggle {
                    display: flex;
                    border: 1px solid #2b2b2b;
                    font-size: 13px;
                    border-radius: 20px;
                    align-items: center;
                    cursor: pointer;
                    height: 100%;
                    font-family: "Barlow-Thin";

                    p {
                        text-align: center;
                        width: 70px;
                        font-family: "Barlow-Thin";
                    }

                    #toggleCurrent {
                        margin: 2px;
                        width: 68px;
                        position: absolute;
                        background-color: black;
                        color: white;
                        height: 34px;
                        border-radius: 20px;
                        line-height: 34px;
                        text-align: center;
                        font-family: "Barlow-Regular";

                        &.Maquette {
                            transform: translateX(0px);
                            transition-duration: 0.15s;
                            transition-timing-function: ease-in-out;
                        }

                        &.Galerie {
                            transform: translateX(68px);
                            transition-duration: 0.15s;
                            transition-timing-function: ease-in-out;
                        }
                    }
                }
            }

        }

        #ficheProjetContainerContent {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 90%;
        }
    }

    #ficheProjetDatas {
        position: relative;
        display: flex;
        flex-direction: row;
        height: 100%;
        overflow: hidden;

        #ficheProjetColumnInfos {
            width: 50%;
            display: flex;

            .datasSubline {
                display: flex;
                flex-direction: column;
                overflow: scroll;
                width: auto;

                &::-webkit-scrollbar {
                    width: 4px;
                    display: none;
                }

                .infoLine {
                    margin: 0px 10px 0px 0px;
                    width: 180px;
                    font-size: 17px;
                    margin-bottom: 20px;

                    .infoLineLabel {
                        margin-bottom: 2px;
                    }

                    .infoLineValue {
                        font-family: "BarlowCondensed-Bold";

                        span {
                            display: block;
                            font-family: "BarlowCondensed-Bold";
                        }
                    }
                }
            }
        }

        #ficheProjetColumnText {

            height: 100%;
            font-size: 17px;
            display: flex;
            flex-direction: column;
            transition-duration: 0.2s;
            padding-bottom: 0px;
            width: 50%;

            p {
                user-select: none;
                pointer-events: none;
                text-justify: justify;
                margin-bottom: 20px;
                line-height: 22px;

                &#descriptionTilte {
                    margin-bottom: 5px;
                }
            }

            li {
                list-style: none;
                padding-left: 20px;
            }

            #ficheProjetText {
                overflow: scroll;
                height: 100%;

                &::-webkit-scrollbar {
                    width: 4px;
                    display: none;
                }

                transition-duration: 0.25s;



            }

            #scrollIndicator {
                position: absolute;
                bottom: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                translate: 0px 40px;
                transition-duration: 0.2s;
            }

            &.scrollable {

                &:hover,
                &.scrolled {

                    transition-duration: 0.2s;
                    padding-bottom: 30px;

                    #scrollIndicator {
                        translate: 0px 0px;
                        transition-duration: 0.2s;
                    }
                }
            }


        }

    }

    #ficheProjetLinks {
        height: 140px;
        display: flex;
        justify-content: right;
        align-items: center;
        margin-top: 10px;

        .link-btn {
            cursor: pointer;
            padding-left: 10px;
            transition-duration: 0.25s;
            font-size: 16px;
            background-color: rgba(255, 255, 255, 0);
            border-radius: 8px;
            color: white;
            max-height: 36px;
            display: flex;
            align-items: center;
            overflow: hidden;
            user-select: none;

            &:hover,
            &.hovered {
                transition-duration: 0.25s;

                svg {
                    pointer-events: none;

                    path {
                        fill: white
                    }
                }

                .link-btn-title {
                    pointer-events: none;
                    transition-duration: 0.25s;
                    width: max-content;
                    padding-right: 16px;
                    opacity: 1;
                    line-height: 36px;
                }

            }

            .link-btn-title {
                pointer-events: none;
                text-align: center;
                transition-duration: 0.25s;
                width: 0px;
                overflow: hidden;
                opacity: 0;

                p {
                    width: max-content;
                }
            }
        }
    }
}

#pdfViewer,
#movieViewer,
#bfViewer {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: white;
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    z-index: 50;

    embed,
    video,
    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }

    #closeViewer {
        cursor: pointer;
        padding-bottom: 20px;
    }

    .bfContent {
        position: relative;
        height: 100%;
        width: 100%;

        .bal-container {
            position: relative;
            width: 100%;
            height: 100%;
            cursor: grab;
            overflow: hidden;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        }

        .bal-after {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        .bal-before {
            display: block;
            position: absolute;
            top: 0;
            /* right: 0; */
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 15;
            overflow: hidden;
        }

        .bal-before-inset {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
        }

        .bal-after img,
        .bal-before img {
            object-fit: cover;
            position: absolute;
            width: 100%;
            height: 100%;
            object-position: 50% 50%;
            top: 0;
            bottom: 0;
            left: 0;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -o-user-select: none;
            user-select: none;
        }

        .bal-beforePosition,
        .bal-afterPosition {
            color: #fff;
            pointer-events: none;
            border-radius: 8px;
            padding: 2px 10px;
        }

        .bal-beforePosition {
            left: 0;
        }

        .bal-afterPosition {
            right: 0;
        }

        .afterLabel,
        .beforeLabel {
            position: absolute;
            bottom: 0;
            margin: 1.0rem;
            padding: 0.4rem 1rem;
            font-size: 1.3rem;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -o-user-select: none;
            user-select: none;
            font-family: "BarlowCondensed-Regular";
        }


        /* handle and arrow */

        .bal-handle {
            height: 41px;
            width: 41px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -20px;
            margin-top: -21px;
            border: 2px solid #fff;
            border-radius: 1000px;
            z-index: 20;
            pointer-events: none;
            box-shadow: 0 0 10px rgb(12, 12, 12);
        }

        .handle-left-arrow,
        .handle-right-arrow {
            width: 0;
            height: 0;
            border: 6px inset transparent;
            position: absolute;
            top: 50%;
            margin-top: -6px;
        }

        .handle-left-arrow {
            border-right: 6px solid #fff;
            left: 50%;
            margin-left: -17px;
        }

        .handle-right-arrow {
            border-left: 6px solid #fff;
            right: 50%;
            margin-right: -17px;
        }

        .bal-handle::before {
            bottom: 50%;
            margin-bottom: 20px;
            box-shadow: 0 0 10px rgb(12, 12, 12);
        }

        .bal-handle::after {
            top: 50%;
            margin-top: 20.5px;
            box-shadow: 0 0 5px rgb(12, 12, 12);
        }

        .bal-handle::before,
        .bal-handle::after {
            content: " ";
            display: block;
            width: 2px;
            background: #fff;
            height: 9999px;
            position: absolute;
            left: 50%;
            margin-left: -1.5px;
        }
    }

}

@keyframes openFp {
    0% {
        transform: translateX(800px);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes loading {
    0% {
        background-color: rgb(0, 0, 0);
    }

    100% {
        background-color: rgba(0, 0, 0, 0.315);
    }
}

@media (min-width: 0) and (max-width: 992px) {
    #ficheProjet {
        width: 85%;
        padding: 0px;
        display: flex;
        align-items: flex-end;

        svg {
            width: 50px;

            path {
                fill: #505050;
            }
        }

        #ficheProjetMedias {
            display: flex;
            min-height: 83%;
            border-radius: 16px;
            overflow: hidden;
            z-index: 40;
            width: 73%;
            align-items: flex-start;
            justify-content: center;
            height: 80%;
            padding-top: 6%;

            #closeBtn {
                position: absolute;
                top: 2vh;
                right: 1vh;
                display: flex;
                cursor: pointer;
            }


            #player {
                display: flex;
                z-index: 10;
                width: 95% !important;
                height: 95% !important;

                #player-2D {
                    display: flex;
                    width: 100%;
                    object-fit: cover;

                    &.fromWidth {
                        border-radius: 12px;

                    }

                    &.fromHeight {
                        border-radius: 12px;
                    }
                }
            }

            #galerie {
                display: flex;
                width: 95% !important;
                height: 95% !important;
                align-items: flex-end;

                #galerieCarrousel {
                    display: flex;
                    height: 100%;
                    width: fit-content;
                    overflow: visible;

                    .galerieMedia {
                        display: flex;
                        justify-content: center;
                        // width: 736px;
                        height: 100%;
                        overflow: hidden;

                        img {
                            height: 100%;
                            user-select: none;
                            pointer-events: none;
                            border-radius: 8px;
                        }
                    }
                }

                #galerieSelector {
                    display: flex;
                    width: 100%;
                    transform: translateY(0);
                    padding-bottom: 3vh;

                    .selector {
                        margin: 4px;
                        height: 15px;
                        width: 15px;
                        border-radius: 50%;
                        background-color: #2b2b2b;
                        transition-duration: 0.25s;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .selectorCurrent {
                        background-color: #fff;
                        height: 7px;
                        width: 7px;
                        border-radius: 50%;
                    }
                }
            }
        }

        #ficheProjetContainerDesc {
            display: flex;
            height: 100%;
            width: 100%;
            flex-direction: column;
            position: absolute;
            left: 0;

            #ficheProjetTopDiv {
                display: flex;
                flex-direction: column;
                width: 27%;
                padding: 3% 2% 2% 2%;
                background-color: #F2F2F2;


                #ficheProjetTitle {
                    display: flex;
                    width: 100%;

                    #nameToUse {
                        display: flex;
                        font-family: "BarlowCondensed-Regular";
                        font-weight: bold;
                        font-size: 18px;
                    }
                }

                #ficheProjetPhasage {
                    display: flex;
                    width: 100%;

                    #fichePhasage {
                        display: flex;
                        font-size: 14px;
                        font-family: "BarlowCondensed-Regular";
                        border-radius: 12px;
                        color: white;
                        padding: 6px;
                        width: fit-content;

                        &.done {

                            background-color: rgb(57, 172, 155);
                        }

                        &.inProgress {
                            background-color: rgb(245, 61, 61);
                        }

                        &.comingSoon {
                            background-color: rgb(89, 145, 192);
                        }
                    }
                }


                #ficheProjetContainerToggle {
                    display: none;
                }
            }

            #ficheProjetContainerContent {
                display: flex;
                background-color: #F2F2F2;
                flex-direction: column;
                width: 27%;
                z-index: 50;
                padding: 0% 2% 0 2%;
                height: 100%;

                #ficheProjetDatas {
                    display: flex;
                    overflow: hidden;
                    height: 95%;

                    #ficheProjetColumnInfos {
                        display: flex;
                        width: 100%;

                        .datasSubline {
                            display: flex;
                            flex-direction: column;
                            overflow-y: scroll;
                            overflow-x: hidden;
                            width: 100% !important;
                            height: 95%;

                            &::-webkit-scrollbar {
                                width: 4px;
                                display: none;
                            }

                            .infoLine {
                                width: 100%;
                                font-size: 15px;
                                margin-bottom: 10px;

                                .infoLineLabel {
                                    margin-bottom: 2px;
                                }

                                .infoLineValue {
                                    font-family: "BarlowCondensed-Bold";

                                    span {
                                        display: block;
                                        font-family: "BarlowCondensed-Bold";
                                    }
                                }
                            }
                        }
                    }

                    #ficheProjetColumnText {
                        display: none;
                        height: 100%;
                        font-size: 17px;
                        position: absolute;
                        transition-duration: 0.2s;
                        padding-bottom: 0px;

                        p {
                            user-select: none;
                            pointer-events: none;
                            text-justify: justify;
                            margin-bottom: 20px;
                            line-height: 22px;

                            &#descriptionTilte {
                                margin-bottom: 5px;
                            }
                        }

                        li {
                            list-style: none;
                            padding-left: 20px;
                        }



                        #scrollIndicator {
                            position: absolute;
                            bottom: 0px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 50%;
                            translate: 0px 40px;
                            transition-duration: 0.2s;
                        }

                        &.scrollable {

                            &:hover,
                            &.scrolled {

                                transition-duration: 0.2s;
                                padding-bottom: 30px;

                                #scrollIndicator {
                                    translate: 0px 0px;
                                    transition-duration: 0.2s;
                                }
                            }
                        }
                    }
                }
            }

            #ficheProjetLinks {
                display: flex;
                padding: 3%;
                justify-content: flex-start;
                height: 17%;
                position: absolute;
                z-index: 50;
                bottom: 0;
                right: 0;
                width: 73%;

                .link-btn {
                    cursor: pointer;
                    transition-duration: 0.25s;
                    font-size: 16px;
                    background-color: rgba(255, 255, 255, 0);
                    border-radius: 8px;
                    color: white;
                    display: flex;
                    padding: 2% 0% 2% 0%;
                    align-items: center;
                    overflow: hidden;
                    user-select: none;
                    z-index: 100;

                    &:hover,
                    &.hovered {
                        transition-duration: 0.25s;

                        svg {
                            pointer-events: none;

                            path {
                                fill: white
                            }
                        }

                        .link-btn-title {
                            pointer-events: none;
                            transition-duration: 0.25s;
                            width: max-content;
                            padding-right: 16px;
                            opacity: 1;
                            line-height: 36px;
                        }

                    }

                    .link-btn-title {
                        pointer-events: none;
                        text-align: center;
                        transition-duration: 0.25s;
                        width: 0px;
                        overflow: hidden;
                        opacity: 0;

                        p {
                            width: max-content;
                        }
                    }
                }
            }

        }






    }
}


#ficheProjetContainerViewer {
    @include mobile-only {
        z-index: 20;
        position: absolute;
        width: 75%;
        height: 80%;
        right: 0;
        top: 0;
    }
}

#toggleDescriptionButton {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    /* Pour placer le bouton au-dessus du contenu */
}

#toggleButtons {
    width: 100%;
    display: flex;
    gap: 3vw;
    z-index: 150;
    justify-content: center;
}

#description-content {
    width: 70%;
    position: absolute;
    top: 9%;
    right: 0;
    margin: 0 1% 0 1%;
    height: 71%;
    overflow: scroll;
    padding: 3% 3% 0 3%;
    border-radius: 16px;
    background-color: #F2F2F2;

    p {
        user-select: none;
        pointer-events: none;
        text-justify: justify;
        margin-bottom: 20px;
        line-height: 22px;

        &#descriptionTilte {
            margin-bottom: 5px;
        }
    }
}

.button-nav-responsive {
    // Sizes
    width: 48px;
    height: 48px;
    // Colors
    background-color: transparent;
    color: #272727;
    // Texts
    font-size: 13px;
    text-align: center;
    // line-height: 64px;
    // Borders
    border-radius: 12px;
    // Comportmements
    cursor: pointer;
    transition-duration: 0.15s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    svg {
        width: 30px;
    }

    &:hover,
    &.activ {
        transition-duration: 0.15s;

        svg {
            path {
                fill: white !important;
            }
        }

    }

}