#projet {
    display: flex;
    z-index: 2;
    width: 20%;
    transform: translateX(-400px);
    animation: open 0.25s forwards;
    height: 100vh;
    position: relative;

    #projetSearch {
        padding: 5% 5% 2% 5%;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        #projetSearchForm {
            display: flex;
            flex-direction: column;
            height: fit-content;
            border-radius: 12px;
            border: solid 1px #505050;
            overflow: hidden;

            #searchTitle {
                align-items: center;
                display: flex;
                justify-content: space-between;
                padding: 2%;
                background-color: #505050;
                color: white;
                font-family: "BarlowCondensed-Regular";
                font-size: 20px;
                font-weight: 600;

                #searchTitleText {
                    width: 100%;
                    justify-content: center;
                    display: flex;
                }

                svg {
                    display: flex;
                    padding: 6px;
                    border-radius: 12px;
                    cursor: pointer;
                    background-color: #2c2c2c00;
                    transition-duration: 0.25s;

                    &:hover {
                        transition-duration: 0.25s;
                        background-color: #2c2c2c;
                    }
                }
            }

            #searchInputs {
                display: flex;
                flex-direction: column;
                padding: 3% 5% 3% 5%;
                background-color: white;

                .searchLine {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    // padding: 2%;

                    label {
                        display: flex;
                        color: rgb(44, 44, 44);
                        font-family: "Barlow-Bold";
                        width: 50%;
                        align-items: center;
                    }

                    .searchSurface {
                        width: 50%;
                        display: flex;
                        flex-direction: column;

                        input {
                            display: flex;
                            width: 100%;
                        }
                    }

                    input {
                        display: flex;
                        width: 50%;
                        height: 3vh;
                        font-size: 16px;
                        border: none;
                        border-bottom: 1px solid black;
                        padding-left: 2%;
                    }



                    select {
                        display: flex;
                        width: 50%;
                        height: 3vh;
                        font-size: 16px;
                        border: none;
                        border-bottom: 1px solid black;
                    }
                }

                .searchLivraison {
                    display: flex;
                    width: 50%;
                    flex-direction: column;
                    padding: 3% 0 3% 0;

                    .livraison-label {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;

                        label {
                            display: flex;
                            font-family: "BarlowCondensed-Regular";
                            font-size: 15px;
                            // color: red;

                            &#maxLbl {
                                display: flex;
                                justify-content: flex-end;
                            }
                        }
                    }

                    .livraison-slider {
                        display: flex;
                        padding: 5% 0% 0% 0%;

                        #searchDates {
                            display: flex;
                            width: 100%;
                            position: relative;

                            input {
                                pointer-events: none;
                                padding-left: 0;
                            }

                            .slider {
                                display: flex;
                                width: 100%;
                                height: 1px;
                                border-radius: 3px;
                                position: absolute;
                                cursor: pointer;
                                align-items: center;
                                -webkit-appearance: none;

                                &::-webkit-slider-thumb {
                                    pointer-events: auto;
                                    -webkit-appearance: none;
                                    height: 10px;
                                    width: 10px;
                                    border-radius: 3px;
                                    border: solid 1px #2c2c2c;
                                }

                                &::-webkit-range-thumb {
                                    pointer-events: auto;
                                    -webkit-appearance: none;
                                    width: 10px;
                                    border-radius: 3px;
                                    border: solid 1px #2c2c2c;
                                }
                            }
                        }
                    }
                }

            }

            .searchButton {
                // colors
                color: white;
                //sizes
                margin-top: 3%;
                height: 36px;
                font-size: 16px;
                // borders
                border-radius: 10px;
                // Text
                text-align: center;
                line-height: 36px;
                // other
                cursor: pointer;

            }
        }

        #projetSearchResults {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;


            #resultsScroller {
                display: flex;
                flex-direction: column;
                overflow: scroll;
                width: 100%;
                gap: 10px;

                &::-webkit-scrollbar {
                    display: none;
                }

                .result {
                    z-index: 12px;
                    display: flex;
                    min-height: 100px;
                    width: 100%;
                    background-color: #f2f4f3;
                    border-radius: 12px;
                    cursor: pointer;
                    transition-duration: 0.25s;
                    justify-content: space-between;
                    overflow: hidden;
                    border: solid 1px #505050;


                    // padding: 1%;


                    .resultDatas {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 2%;
                        width: 60%;
                    }

                    h3 {
                        font-size: 16px;
                    }

                    .resultImages {
                        display: flex;
                        max-width: 30%;
                        align-items: center;
                        justify-content: center;
                        height: 100px;

                        img {
                            height: 100%;
                        }
                    }

                    &:hover {
                        transition-duration: 0.25s;
                        color: white;
                    }

                }
            }

            svg {
                cursor: pointer;
                display: flex;
                justify-content: center;
                color: red;
                height: 40px;
            }
        }

    }
}

@keyframes openProjects {
    0% {
        transform: translateX(-400px);
    }

    100% {
        transform: translateX(0px);
    }
}

*:focus {
    outline: none;
}

@media (min-width: 0) and (max-width: 992px) {

    #projet {
        width: 25%;
        height: fit-content;

        #projetSearch {
            padding: 3vh 0vh 0vh 3vh;

            #projetSearchResults {
                height: 80vh !important;
            }

            .toggleFormIcon {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition-duration: 0.15s;

                svg {
                    background-color: #F2F2F2;
                    border-radius: 12px;
                    display: flex;
                    justify-content: center;
                    width: 50px;

                    path {
                        fill: red;
                    }

                    circle {
                        fill: red;
                    }
                }
            }

            #projetSearchForm {

                #searchTitle {
                    padding: 2%;
                    font-size: 19px;

                    #searchTitleText {
                        display: flex;
                    }

                    svg {
                        display: flex;
                        padding: 6px;
                        border-radius: 12px;
                        cursor: pointer;
                        background-color: #2c2c2c00;
                        transition-duration: 0.25s;

                        &:hover {
                            transition-duration: 0.25s;
                            background-color: #2c2c2c;
                        }
                    }
                }

                #searchInputs {
                    padding: 5%;

                    .searchLine {
                        padding: 0 5% 5% 5%;

                        label {
                            display: flex;
                            color: rgb(44, 44, 44);
                            font-family: "Barlow-Bold";
                            width: 50%;
                            font-size: 15px;
                            align-items: center;
                        }

                        &#searchResp {
                            display: none;
                        }

                        .searchSurface {
                            width: 50%;
                            display: flex;
                            flex-direction: column;

                            input {
                                display: flex;
                                width: 100%;
                            }
                        }

                        input {
                            display: flex;
                            width: 50%;
                            height: 8vh;
                            font-size: 15px;
                            border: none;
                            border-bottom: 1px solid black;
                            padding-left: 2%;
                        }

                        select {
                            display: flex;
                            width: 50%;
                            height: 8vh;
                            font-size: 15px;
                            border: none;
                            border-bottom: 1px solid black;
                        }
                    }

                    .searchLivraison {
                        display: flex;
                        width: 50%;
                        flex-direction: column;

                        .livraison-label {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;

                            label {
                                display: flex;
                                font-family: "BarlowCondensed-Regular";
                                font-size: 15px;
                                color: red;

                                &#maxLbl {
                                    display: flex;
                                    justify-content: flex-end;
                                }
                            }
                        }

                        .livraison-slider {
                            display: flex;
                            padding: 10% 2% 2% 2%;

                            #searchDates {
                                display: flex;
                                width: 100%;
                                position: relative;
                                height: fit-content;

                                input {
                                    pointer-events: none;
                                }

                                .slider {
                                    display: flex;
                                    width: 100%;
                                    height: 1px;
                                    border-radius: 3px;
                                    position: absolute;
                                    cursor: pointer;
                                    align-items: center;
                                    -webkit-appearance: none;

                                    &::-webkit-slider-thumb {
                                        pointer-events: auto;
                                        -webkit-appearance: none;
                                        height: 10px;
                                        width: 10px;
                                        border-radius: 3px;
                                        border: solid 1px #2c2c2c;
                                    }

                                    &::-webkit-range-thumb {
                                        pointer-events: auto;
                                        -webkit-appearance: none;
                                        height: 10px;
                                        width: 10px;
                                        border-radius: 3px;
                                        border: solid 1px #2c2c2c;
                                    }
                                }
                            }
                        }
                    }

                }

                .searchButton {
                    font-size: 15px;
                }
            }

            #projetSearchResults {
                flex-direction: column;
                justify-content: center;
                align-items: center;

                #resultsScroller {
                    display: flex;
                    flex-direction: column;
                    overflow: scroll;
                    width: 100%;
                    // gap: 10px;
                    gap: 5px;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .result {
                        z-index: 12px;
                        display: flex;
                        width: 100%;
                        background-color: #f2f4f3;
                        border-radius: 12px;
                        cursor: pointer;
                        justify-content: space-between;
                        transition-duration: 0.25s;
                        padding: 2%;
                        border: solid 1px #505050;
                        min-height: fit-content;
                        overflow: visible;

                        .resultDatas {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            width: 100%;
                        }

                        h3 {
                            font-size: 15px;
                        }

                        p strong {
                            font-size: 15px;
                        }

                        p {
                            font-size: 15px;
                        }

                        .resultImages {
                            display: none;
                        }

                        &:hover {
                            transition-duration: 0.25s;
                            color: white;
                        }

                    }
                }
            }
        }
    }
}