#root {
    &>#fullScreen {
        position: absolute;
        // @include mobile-only {
        //     position: initial;
        // }
        left: 110px;
        bottom: 10px;
        z-index: 2;

        &.noHeader {
            left: 10px;
        }

        // @include mobile-only {
        //     display: none;
        // }

        .bouton {
            &:not(.activ) {
                background-color: #f5f5f5;
            }
        }

       
    }
}

#headerBottom {
    #fullScreen {
        position: relative;

        &:not(.activ) {
            background-color: transparent;
        }

        #btn-fullScreen:hover {
            color: white
        }
    }
}

#mobile-test {
    display: none;
}

@media (min-width: 0) and (max-width: 992px) {

    #headerBottom {
        #fullScreen {
            display: none;

            &:not(.activ) {
                background-color: transparent;
            }
        }
    }

    #mobile-test {
        position: absolute;
        z-index: 9998;
        background-color: rgba(43, 43, 43, 0.842);
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 80px;
        font-family: "Barlow-Bold";

        font-size: 30px;

        #fullScreen {
            #btn-fullScreen {
                background-color: #f5f5f5;
                color: black;
            }

            #btn-fullScreen.activ {
                background-color: red;
                color: white;
            }

            .button {
                font-size: 14px;
            }
        }
    }
}