#player {
    z-index: 0;
    position: absolute;
    right: 0px;
    height: 100%;
    width: calc(100% - 100px);

    &.noHeader {
        width: 100%;
    }

    #player-2D {
        display: flex;
        align-items: center;
        justify-content: center;

        &.fromHeight {
            position: absolute;
            height: 100%;
            width: auto;
        }

        &.fromWidth {
            position: absolute;
            width: 100%;
            height: auto;
        }
    }

    .puce {

        position: absolute;

        &:hover {
            z-index: 100;

            .puce-text {
                &.noToolTip {
                    display: block;
                }
            }
        }

        .puce-container {
            position: absolute;
            background: transparent;
            overflow: hidden;
            height: 92px;
            width: 30px;
            border-radius: 50px;
            transform: translate(-14px, -22px);

            &.current {
                animation: currentPoiAnimationMaquette 1.2s alternate infinite ease-in-out;
                scale: 1.4;
            }


            .puce-base {
                transform: translateY(-1px) scale(1.5) rotate(45deg);
                width: 30px;
                height: 30px;
                border-radius: 0% 90% 0% 90%;
                cursor: pointer;

                .puce-center {
                    width: 12px;
                    height: 12px;
                    background-color: white;
                    border-radius: 50%;
                    transform: translate(10px, 10px) rotate(-45deg);
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        height: 10px;
                    }
                }
            }

        }

        .puce-text {
            position: absolute;
            left: 23px;
            top: -35px;
            white-space: nowrap;
            padding: 5px 9px;
            color: white;
            border-radius: 5px;
            text-align: start;

            &.noToolTip {
                display: none;
            }
        }
    }

    #playerNavigation {
        z-index: 8;
        // positions
        position: absolute;
        bottom: 36px;
        // left: 22%;
        // display
        display: flex;
        gap: 10px;
        font-size: 16px;


        .navigationLvl {
            min-width: 131px;
            text-align: center;
            display: flex;
            flex-direction: column-reverse;

            .navigationChoice {
                padding: 10px;
                text-align: center;
                border-radius: 12px;
            }

            .navigationChoiceView {
                text-align: center;
                cursor: pointer;
                border-radius: 12px;

                .navigationChoice {
                    padding: 10px;
                    text-align: center;
                    border-radius: 12px;
                }

                .navigationChoiceMain {
                    padding: 10px;
                    text-align: center;
                    border-radius: 12px;
                }
            }
        }
    }

}


.playerProjetsCountInfo {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transform: translate(-150%, 7px);
    opacity: 0.9;
    pointer-events: none;

}

#player-boussole {
    width: 90px;
    height: 90px;
    position: absolute;
    right: 50px;
    top: 50px;
    display: flex;
    border-radius: 15px;

    svg {
        width: 100%;
        height: 100%;
        color: white;
    }
}

#player-loading {
    position: absolute;
    right: 20px;
    bottom: 40px;
    color: white;
    width: fit-content;
}

#containerPlayerLegendRotate {
    position: absolute;
    // right: 22%;
    bottom: 36px;
    display: flex;
    gap: 10px;
    z-index: 8;
    width: fit-content;
}

#playerRotate {
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 12px;
    height: 40px;

    .playerArrow {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        height: 100%;
        width: 40px;

        svg {
            fill: white;
            width: 20px;
        }

        &:active {
            transform: scale(0.9);
            transition: transform 0.2s ease;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        }
    }

    #playerRotateImg {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 39px;

        svg {
            fill: white;
        }
    }
}

#containerPlayerLegend {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 12px;
    padding: 0px 5px 0px 5px;

    svg {
        fill: white;
    }
}

#playerLegend {
    position: absolute;
    bottom: 130%;
    height: auto;
    display: flex;
    width: 100%;

    .caption {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border: 1px solid #505050;
        border-radius: 16px;
        height: fit-content;
        width: fit-content;
        z-index: 2;
        position: relative;
        top: 0%;

        &.left {
            left: 0px;
            width: 100%;
        }

        &.right {
            right: 0px;
        }

        .captionContent {
            p {
                text-align: left;
                width: calc(100% - 5px);
            }

            h3 {
                margin-bottom: 0px;
            }
        }
    }
}



@media (min-width: 0) and (max-width: 992px) {
    #player #playerNavigation {
        position: absolute;
        bottom: 3vh;
        left: 30% !important;
        display: flex;
        width: fit-content;

        .navigationLvl {
            min-width: 60px;

            .navigationChoiceView {
                cursor: pointer;
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: flex-end;

                &.hover {
                    display: none !important;
                }

                .navigationChoiceMain {
                    padding: 5px;
                    text-align: center;
                    border-radius: 12px;



                    svg {
                        width: 25px !important;
                        height: 25px !important;
                    }
                }


                &.activ {
                    width: fit-content;

                    .navigationChoiceMain {
                        position: absolute;
                        right: 0px;
                        bottom: 0px;
                        padding: 6px;
                        text-align: center;
                        border-radius: 12px;

                        svg {
                            width: 28px !important;
                            height: 28px !important;

                            .st0 {
                                fill: white;
                            }
                        }
                    }
                }
            }
        }
    }

    #player-boussole {
        right: 2vh;
        top: 3vh;
        width: 50px;
        height: 50px;
    }

    #player-loading {
        bottom: 3vh;
        left: 16%;
    }

    #containerPlayerLegendRotate {
        position: absolute;
        right: 3vh !important;
        bottom: 3vh;
        display: flex;
        gap: 10px;
        z-index: 8;
        width: fit-content;
        transform: translateX(0%) !important;
    }

    #playerRotateImg {
        svg {
            fill: white;
            width: 34px;
        }
    }

    #containerPlayerLegend {
        svg {
            fill: white;
            width: 34px;
        }
    }

    #playerLegend {
        position: absolute;
        bottom: 115%;
        height: auto;
        display: flex;
        width: 100%;

        .caption {
            display: flex;
            flex-direction: column;
            background-color: #fff;
            border: 1px solid #505050;
            border-radius: 16px;
            height: fit-content;
            width: fit-content;
            z-index: 2;
            position: relative;
            top: 0%;

            &.left {
                left: 0px;
                width: 100%;
            }

            &.right {
                right: 0px;
            }

            .captionContent {
                padding: 3%;

                p {
                    font-size: 15px;
                    text-align: left;
                    width: calc(100% - 10px);
                }

                h3 {
                    font-size: 17px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}