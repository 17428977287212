// responsive
$breakpoints: (
    laptop : 1400px,
    mobile: 599px,
    mobile-iphone: 320px,
);

// fonctions ---> TODO TEMP : A passer dans un fichier dedie
@mixin laptop-only {
    @media screen and (max-width : map-get($breakpoints, laptop)){
        @content;
    }
}

@mixin mobile-only {
    @media screen and (max-height : map-get($breakpoints, mobile)){
        @content;
    }
}

@mixin mobile-iphone-only {
    @media screen and (max-height : map-get($breakpoints, mobile-iphone)){
        @content;
    }
}

@mixin no-mobile {
    @media screen and (min-height : map-get($breakpoints, mobile)){
        @content;
    }
}


@mixin mobile-isnt-landscape {
    @media (max-device-width : 1000px) and (orientation: portrait) {
        @content;
    }
}
