$font-path: '../../public/fonts/';

// //////////////////////////////////////////////////////
// Barlow STD

// Barlow-Black
@font-face {
  font-family: "Barlow-Black";
  src: url($font-path + "Barlow-Black.ttf") format("truetype");
}
// Barlow-BlackItalic
@font-face {
  font-family: "Barlow-BlackItalic";
  src: url($font-path + "Barlow-BlackItalic.ttf") format("truetype");
}
// Barlow-Bold
@font-face {
  font-family: "Barlow-Bold";
  src: url($font-path + "Barlow-Bold.ttf") format("truetype");
}
// Barlow-BoldItalic
@font-face {
  font-family: "Barlow-BoldItalic";
  src: url($font-path + "Barlow-BoldItalic.ttf") format("truetype");
}
// Barlow-ExtraBold
@font-face {
  font-family: "Barlow-ExtraBold";
  src: url($font-path + "Barlow-ExtraBold.ttf") format("truetype");
}
// Barlow-ExtraBoldItalic
@font-face {
  font-family: "Barlow-ExtraBoldItalic";
  src: url($font-path + "Barlow-ExtraBoldItalic.ttf") format("truetype");
}
// Barlow-ExtraLight
@font-face {
  font-family: "Barlow-ExtraLight";
  src: url($font-path + "Barlow-ExtraLight.ttf") format("truetype");
}
// Barlow-ExtraLightItalic
@font-face {
  font-family: "Barlow-ExtraLightItalic";
  src: url($font-path + "Barlow-ExtraLightItalic.ttf") format("truetype");
}
// Barlow-Italic
@font-face {
  font-family: "Barlow-Italic";
  src: url($font-path + "Barlow-Italic.ttf") format("truetype");
}
// Barlow-Light
@font-face {
  font-family: "Barlow-Light";
  src: url($font-path + "Barlow-Light.ttf") format("truetype");
}
// Barlow-LightItalic
@font-face {
  font-family: "Barlow-LightItalic";
  src: url($font-path + "Barlow-LightItalic.ttf") format("truetype");
}
// Barlow-Medium
@font-face {
  font-family: "Barlow-Medium";
  src: url($font-path + "Barlow-Medium.ttf") format("truetype");
}
// Barlow-MediumItalic
@font-face {
  font-family: "Barlow-MediumItalic";
  src: url($font-path + "Barlow-MediumItalic.ttf") format("truetype");
}
// Barlow-Regular
@font-face {
  font-family: "Barlow-Regular";
  src: url($font-path + "Barlow-Regular.ttf") format("truetype");
}
// Barlow-SemiBold
@font-face {
  font-family: "Barlow-SemiBold";
  src: url($font-path + "Barlow-SemiBold.ttf") format("truetype");
}
// Barlow-SemiBoldItalic
@font-face {
  font-family: "Barlow-SemiBoldItalic";
  src: url($font-path + "Barlow-SemiBoldItalic.ttf") format("truetype");
}
// Barlow-Thin
@font-face {
  font-family: "Barlow-Thin";
  src: url($font-path + "Barlow-Thin.ttf") format("truetype");
}
// Barlow-ThinItalic
@font-face {
  font-family: "Barlow-ThinItalic";
  src: url($font-path + "Barlow-ThinItalic.ttf") format("truetype");
}

// //////////////////////////////////////////////////////
// Barlow Condensed

// BarlowCondensed-Regular
@font-face {
  font-family: "BarlowCondensed-Regular";
  src: url($font-path + "BarlowCondensed-Regular.ttf") format("truetype");
}

// BarlowCondensed-Bold
@font-face {
  font-family: "BarlowCondensed-Bold";
  src: url($font-path + "BarlowCondensed-Bold.ttf") format("truetype");
}

// BarlowCondensed-SemiBold
@font-face {
  font-family: "BarlowCondensed-SemiBold";
  src: url($font-path + "BarlowCondensed-SemiBold.ttf") format("truetype");
}