#filtres {
    display: flex;
    width: 15%;
    z-index: 2;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 1%;


    #filtresSelection {
        display: flex;
        flex-direction: column;
        transform: translateX(-200px);
        animation: open 0.25s forwards;
        width: 100%;
        justify-content: center;

        .buttonLong {
            width: 100%;
            // margin-left: 0;
        }
    }
}



#filtre {
    z-index: 1;
}

@keyframes open {
    0% {
        transform: translateX(-200px);
    }

    100% {
        transform: translateX(0px);
    }
}

@media (min-width: 0) and (max-width: 992px) {

    #filtres {
        width: 20%;

        #filtresSelection {
            gap: 5px;
        }
    }
}