.mapboxgl-marker {
    &:hover {
        z-index: 1;
    }
}

.poi-container {
    background: transparent;
    overflow: hidden;
    height: 92px;
    width: 30px;
    border-radius: 50px;

    &:hover {
        z-index: 100;

        .poi-text {
            &.noToolTip {
                display: block;
            }
        }
    }

    &.current.map {
        animation: currentPoiAnimationMap 1.2s alternate infinite ease-in-out;
        scale: 1.4;
    }

    .poi-base {
        transform: translateY(-1px) scale(1.5) rotate(45deg);
        width: 30px;
        height: 30px;
        border-radius: 0% 90% 0% 90%;
        cursor: pointer;

        .poi-center {
            width: 12px;
            height: 12px;
            background-color: white;
            border-radius: 50%;
            transform: translate(10px, 10px) rotate(-45deg);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .poi-center-icon {
            width: 12px;
            height: 13px;
            border-radius: 50%;
            transform: translate(10px, 10px) rotate(-45deg);
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                height: 12px;
            }

        }
    }

    .poi-text {
        position: absolute;
        left: 35px;
        top: -14px;
        white-space: nowrap;
        padding: 5px 9px;
        color: white;
        border-radius: 5px;
        text-align: start;
        font-size: 16px;

        &.noToolTip {
            display: none;
        }
    }
}


@keyframes currentPoiAnimationMap {
    0% {
        transform: translateY(0px);
        scale: 1.5;
    }

    100% {
        transform: translateY(2px);
        scale: 1.4;
    }
}


@keyframes currentPoiAnimationMaquette {
    0% {
        transform: translateY(0px);
        scale: 1.5;
        transform: translate(-9px, -17px);

    }

    100% {
        transform: translateY(2px);
        scale: 1.4;
        transform: translate(-9px, -17px);
    }
}


@media (min-width: 0) and (max-width: 915px) {
    .mapboxgl-ctrl-bottom-right {
        display: none;
    }

    .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
        margin: 0 !important;
        top: 1vh;
        position: absolute;
        right: 3vh;
    }

    .poi-container {
        .poi-text {
            font-size: 15px;
        }
    }
}