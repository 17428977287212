.header {
    // Sizes
    width: 100px;
    height: 100%;
    // Colors
    background-color: #ffffff;
    // Display
    display: flex;
    z-index: 3;

    .toggleMenu {
        // Sizes
        height: 100%;
        width: 100%;
        pointer-events: none;
        // Display
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        // Animation
        transition-duration: 0.2s;
        transform: translateX(0px);


        &.lvl2 {
            transition-duration: 0.2s;
            transform: translateX(-100px);
        }

        #btnToggles {
            pointer-events: all;
            height: 68%;
        }

        #separator {
            display: none;
        }
    }

    .headerContent {
        // Sizes
        min-width: 100%;
        height: 100%;
        // Display
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        #headerTop,
        #headerBottom {
            height: 10%;
            pointer-events: all;
            cursor: pointer;

            img {
                width: 100%;
            }

            #homeButton {
                display: none;
                z-index: 2;
                position: absolute;
                transform: translateX(13px);
            }
        }
    }

    .subHeader {
        // Sizes
        height: 40%;
        align-self: center;
        min-width: 100px;
        // Display
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        // Animation
        transition-duration: 0.2s;
        transform: translateX(-200px);

        &.lvl2 {
            transition-duration: 0.2s;
            transform: translateX(-100px);
            height: 50%;
        }

        #containerToggleMediaDisplay {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: fit-content;
            pointer-events: all;

            #toggleMediaDisplay {
                display: flex;
                border-radius: 12px;
                width: 64px;
                background-color: #e7e7e7;
                flex-direction: column;
                cursor: pointer;
                pointer-events: all;
                align-items: center;
                height: 100%;

                #button2D3D {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 64px;
                    height: 64px;
                    font-size: 16px;
                }

                .button {
                    display: flex;
                    position: absolute;

                    &.is2D {
                        transition-duration: 0.2s;
                        transform: translateY(-10%);
                        height: 64px;
                        font-size: 16px;
                        font-family: "BarlowCondensed-SemiBold";
                    }

                    &.is3D {
                        transition-duration: 0.2s;
                        transform: translateY(90%);
                        height: 64px;
                        font-size: 16px;
                        font-family: "BarlowCondensed-SemiBold";
                    }
                }
            }
        }
    }
}

#headerBottom {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    #logoPromoteur {
        padding-bottom: 10px;
        width: 80px;

        @include mobile-only {
            display: none;
        }
    }
}

@media (min-width: 0) and (max-width: 992px) {

    .header {
        width: 15%;

        .headerContent {
            display: none;
        }

        .subHeader {
            height: 100%;
            transform: none;

            #containerToggleMediaDisplay {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 40%;
                box-shadow: 0 5px 8px -4px gray;

                #toggleMediaDisplay {
                    display: flex;
                    border-radius: 12px;
                    background-color: #e7e7e7;
                    flex-direction: column;
                    cursor: pointer;
                    pointer-events: all;
                    align-items: center;
                    height: fit-content;

                    #button2D3D {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 64px;
                        height: 64px;
                        font-size: 15px;
                    }

                    .button {
                        display: flex;
                        position: absolute;

                        &.is2D {
                            transition-duration: 0.2s;
                            transform: translateY(-10%);
                            height: 64px;
                            font-size: 15px;
                        }

                        &.is3D {
                            transition-duration: 0.2s;
                            transform: translateY(90%);
                            height: 64px;
                            font-size: 15px;
                        }
                    }

                }
            }

            .toggleMenu {
                height: 100%;

                #btnToggles {
                    pointer-events: all;
                    height: 60%;
                    overflow: scroll;
                    padding-top: 14%;

                    .button {
                        margin: 0;
                        margin-bottom: 5px;
                    }
                }
            }

            &.lvl2 {
                height: 100%;
                transform: none;
                width: 100%;
            }
        }
    }
}