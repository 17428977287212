#lotHover {

    position: absolute;
    transform: translate(-7.5px, 8px);
    pointer-events: none;

    &:hover {
        z-index: 100;
        #lotHover-text {
            &.noToolTip {
                display: block;
            }
        }
    }

    #lotHover-container {


        background:transparent;
        overflow: hidden;
        height: 92px;
        width: 30px;
        border-radius: 50px;

        #lotHover-base {
            transform: translateY(-1px) scale(1.5) rotate(45deg);
            width: 30px;
            height: 30px;
            border-radius: 0% 90% 0% 90%;
            cursor: pointer;
    
            #lotHover-center {
                width: 12px;
                height: 12px;
                background-color: white;
                border-radius: 50%;
                transform: translate(10px, 10px) rotate(-45deg);
                display: flex;
                justify-content: center;
                align-items: center;
    
                svg {
                    height: 10px;
                }
            }
        }

    }

    #lotHover-text {
        position: absolute;
        left: 35px;
        top: -14px;
        white-space: nowrap;
        padding: 5px 9px;
        color: white;
        border-radius: 5px;
        text-align: start;
        z-index: 10;
    
        &.noToolTip {
            display: none;
        }
    }

}