#creditsAsy {
    display: flex;
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 6vw;
    height: 35px;
    border-top-left-radius: 14px;
    background-color: white;
    font-weight: bold;
    font-size: 14px;
    align-items: center;
    padding: 2px;
    justify-content: space-evenly;
    z-index: 2;

    .creditsImage {
        height: 30px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

#creditsAsy:hover {
    cursor: pointer;
    background-color: red;
    color: white;

    .creditsImage {
        img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%);
        }
    }
}

@media (min-width: 0) and (max-width: 992px) {
    #creditsAsy {
        display: none;
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 10vw;
        height: 30px;
        border-top-left-radius: 14px;
        font-family: 'Barlow-Bold';
        background-color: white;
        font-size: 9px;
        align-items: center;
        padding: 0px;
        justify-content: space-evenly;

        .creditsImage {
            height: 10px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}