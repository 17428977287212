#cartes {
    display: flex;
    width: 15%;
    z-index: 2;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 1%;

    #cartesCaption {
        position: absolute;
        bottom: 36px;
        right: 22%;

        #playerLegend {
            width: fit-content;
            transform: translateX(-78%);
        }

        .captionContent {
            width: 180px;
        }
    }

    #cartesSelection {
        display: flex;
        flex-direction: column;
        transform: translateX(-200px);
        animation: open 0.25s forwards;
        width: 100%;
        justify-content: center;

        .buttonLong {
            width: 100%;
            // margin-left: 0;
        }
    }
}

#carte {
    z-index: 1;
}

@keyframes open {
    0% {
        transform: translateX(-200px);
    }

    100% {
        transform: translateX(0px);
    }
}

@media (min-width: 0) and (max-width: 915px) {

    #cartes {
        width: 20%;

        #cartesSelection {
            gap: 5px;
        }

        #cartesCaption {
            right: 3vh;
            bottom: 3vh;
            transform: translateX(0);

            .captionContent {
                padding: 3%;

                p {
                    font-size: 15px;
                    text-align: left;
                    width: calc(100% - 10px);
                }

                h3 {
                    font-size: 17px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}