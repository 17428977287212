#home {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100vh;
        object-fit: fill;
    }

    h1 {
        padding: 112px;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        img {
            width: auto;
        }
    }

    #startBtn {
        cursor: pointer;
        color: white;
        background-color: #383b3cf5;
        position: absolute;
        bottom: 260px;
        margin: 0 calc(50% - 200px);
        width: 300px;
        height: 60px;
        display: flex;
        justify-content: center;
        font-family: "Barlow-Bold";
        align-items: center;
        border-radius: 14px;
    }

    #home-containernavigation {
        display: flex;
        position: absolute;
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 5vh;

        #homeNavigation {
            display: flex;
            width: 50vw;
            height: 70vh;
            flex-wrap: wrap;


            .homeNavigationBtn {
                flex: 40%;
                border-radius: 22px;
                margin: 15px;
                border: 2px solid #2c2c2c;
                background-color: rgba(240, 248, 255, 0.35);
                color: rgb(255, 255, 255);
                color: #2c2c2c;
                font-family: "Barlow-Bold";
                font-size: 24px;
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                text-align: center;

                &:hover {
                    background-color: rgba(240, 248, 255, 0.53);
                }
            }
        }

    }
}

@media (min-width: 0) and (max-width: 992px) {
    #home {

        #startBtn {
            cursor: pointer;
            color: white;
            background-color: #383b3cf5;
            position: absolute;
            bottom: 40px;
            margin: 0 calc(50% - 200px);
            width: 300px;
            height: 60px;
            display: flex;
            justify-content: center;
            font-family: "Barlow-Bold";
            align-items: center;
            border-radius: 14px;
        }

        #home-containernavigation {
            display: flex;
            position: absolute;
            height: 100vh;
            width: 100vw;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            padding-bottom: 5vh;

            #homeNavigation {
                width: 90vw;
            }
        }
    }

}