
.legendLine {
    display: flex;
    justify-content: left;
    align-items: center;

    .linePt {
        width: 10px;
        height: 10px;
        margin-right: 10px;
        border-radius: 50%;
    }
}