#rechercher {
    // Display
    display: flex;
    justify-content: space-between;
    // Size
    width: 100%;
    // Colors
    background-color: white;
    // Paddings   
    padding: 20px;
    

    #rechercherForm {

        width: 600px;
        padding: 0px 40px 20px 40px;
        // display
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        #rechercherFormInputs {
            .RechercherFilter {

                padding: 20px 0px;

                .filterLabel {
                    display: block;
                    font-family: "Barlow-Bold";
                    font-size: 16px;
                    margin-bottom: 20px;
                    color: #2e2e2e;
                }
            }
        }

        #rechercherFormSubmit {
            // Sizes
            width: 100%;
            height: 70px;
            // Colors
            background-color: #ff0000;;
            color: white;
            // Texts
            text-align: center;
            line-height: 40px;
            // Borders
            border-radius: 20px;
            // Cursor
            cursor: pointer;
            // Display
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Barlow-Bold';
    
        }
    }

    #rechercherResults {

        display: flex;
        flex-direction: column;
        width: 100%;

        #resultsTitle {
            margin-left: 20px;
            font-size: 24px;
            margin-top: 20px;
            margin-bottom: 20px;
            font-family: "BarlowCondensed-Bold";
            color: #2e2e2e;
        }

        #resultsList {
            text-align: center;
            overflow: scroll;
            display: flex;
            flex-wrap: wrap;

            &::-webkit-scrollbar {
                display: none;
            }

            .rechercherResult {
                background-color: #f2f2f2;
                border-radius: 20px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                height: 170px;
                margin-bottom: 20px;
                margin-left: 20px;
                width: 332px;
                cursor: pointer;
                padding: 10px;
                box-sizing: border-box;
                border: 2px solid #f2f2f2;
    
                &:hover {
                    border: 2px solid #2b2b2b;
                }

                h3 {
                    font-size: 24px;
                    color: #2e2e2e;
                    font-family: 'BarlowCondensed-Bold';
                    margin-bottom: 14px;
                }

                .resultDatas {

                    width: 100%;
                    color: #2e2e2e;

                    .dataLine {

                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 4px;

                        label {
                            font-family: "BarlowCondensed-Regular";
                            font-size: 13px;
                        }

                        p {
                            font-family: "Barlow-Bold";
                            font-size: 13px;
                            text-align: end;
                        }
                    }
                }
            }
        }

    }
}