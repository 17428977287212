.buttonLong {
    // Sizes
    // width: 180px;
    width: 100%;
    height: 40px;
    // margin: 8px 18px;
    margin: 8px 0px;
    // Colors
    background-color: #f2f4f3;
    color: #272727;
    // Texts
    font-size: 16px;
    // Borders
    border-radius: 12px;
    border: solid 1px #272727;
    // Comportmements
    cursor: pointer;
    transition-duration: 0.15s;
    align-items: center;
    display: flex;

    svg {
        width: 30px;
        margin-left: 5px;
        margin-right: 10px;
        transition-duration: 0.15s;
    }

    &:hover,
    &.activ {
        color: white;
        transition-duration: 0.15s;

        svg {
            path {
                fill: white;
                transition-duration: 0.15s;
            }
        }
    }
}

@media (min-width: 0) and (max-width: 992px) {
    .buttonLong {
        width: 172px!important;
        margin: 0px;
        height: fit-content;
        font-size: 15px;

        svg {
            width: 30px;
            margin-left: 0px;
            margin-right: 0px;
            padding: 2px;
            transition-duration: 0.15s;
        }
    }
}