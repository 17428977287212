.container-caption {
    display: flex;
    width: 100%;
    justify-content: center;
    // align-items: center;
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

.caption {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid black;
    border-radius: 16px;
    height: fit-content;
    width: auto;
    z-index: 2;
    // top: 1%;
    width: 100%;
    animation: slideUp 0.25s forwards;

    // &.left {left: 110px;}
    // &.right {right: 110px;}


    .captionTitle {
        display: none;
        justify-content: space-around;
        align-items: center;
        background-color: #505050;
        color: white;
        padding: 10px 10px;
        border-radius: 12px;

        .captionIcon {
            width: 100%;
            padding: 0px;
            margin: 0px;

            svg {
                height: 26px;

                path {
                    fill: white;
                }
            }
        }

        .captionName {
            min-width: max-content;
            font-weight: 100;
            font-size: 20px;
        }

        .captionReturn {
            width: 100%;
            cursor: pointer;
        }
    }

    .captionContent {
        text-align: center;
        padding: 3%;

        p {
            text-align: left;
            font-family: "BarlowCondensed-Regular";
            width: calc(100% - 20px);
            font-size: 16px;
        }

        h3 {
            margin-bottom: 20px;
            font-size: 20px;
            font-family: "BarlowCondensed-SemiBold";
        }
    }
}